/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin responsive($breakpoint) {
    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {
      // Prints a media query based on the value
      @media #{inspect(map-get($breakpoints, $breakpoint))} {
        @content;
      }
    }

    // If the key doesn't exist in the map
    @else {
      @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
  }

@mixin calendar-widget {
    margin: 0 0 0 0.75rem;
    &:empty {
      background-color: $oxford;
      height: 29px;
      width: 34px;
      border-radius: 4px;
    }
    #scheduling-calendar-widget {
      height: 29px;
      width: 34px;
      .calendar-icon {
        background-image: url('../img/navigation-icons/calendar-appointment.svg');
        cursor: pointer;
        background-repeat: no-repeat;
        border-radius: 4px;
        padding: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        }
      }

      // hover works for none media screens
      @media not all and (pointer: coarse) {
        .calendar-icon:hover {
          background-image: url('../img/navigation-icons/calendar-appointment-hover.svg');
        }
      }
    }
    .widget-content {
      right: 0;
      left: unset;
      #calendarLoader {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255,255,255,0.75);
      }
      @include responsive(small) {
        padding: 1rem;
      }
    }
}

@mixin truncate($maxLines: 1, $lineHeight: 1.25em) {
  display: -webkit-box;
  -webkit-line-clamp: $maxLines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: $lineHeight;
  max-height: $maxLines * $lineHeight;
}